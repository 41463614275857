export const SHOW_PROGRESS          = 'SHOW_PROGRESS';
export const HIDE_PROGRESS          = 'HIDE_PROGRESS';
export const SHOW_PROGRESS_ERROR    = 'SHOW_PROGRESS_ERROR';

export const SHOW_DRAWER    = 'SHOW_DRAWER';
export const HIDE_DRAWER    = 'HIDE_DRAWER';
export const TOGGLE_DRAWER  = 'TOGGLE_DRAWER';

export const TOGGLE_LIGHT_THEME = 'TOGGLE_LIGHT_THEME';
export const TOGGLE_DARK_THEME  = 'TOGGLE_DARK_THEME';
