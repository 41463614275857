import {SHOW_DRAWER, HIDE_DRAWER, TOGGLE_DRAWER} from '../actions/action-types';

const getInitialState = () => {
    return {
        show: false
    };
};

const show = (state = getInitialState(), action: any) => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                ...{
                    show: !state.show
                }
            };
        case SHOW_DRAWER:
            return {
                ...state,
                ...{
                    show: true
                }
            };
        case HIDE_DRAWER:
            return {
                ...state,
                ...{
                    show: false
                }
            };
        default:
            return state;
    }
};

export const drawer = (state: any = {}, action: any) => (show(state, action));
