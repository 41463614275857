/**
 * @see https://jaxenter.de/tutorial-typescript-typisierung-59192
 * @see https://github.com/typestack/class-validator/blob/master/README.md
 * @see https://redux.js.org/
 * @see https://reactjs.org/docs/context.html
 *
 * @see https://github.com/wmonk/create-react-app-typescript/blob/master/packages/react-scripts/template/README.md
 * @see https://material.io/tools/icons/?style=baseline
 *
 * @see https://material-ui.com/guides/right-to-left/
 * @see https://material-ui.com/guides/csp/
 *
 * @TODO: If CRA supports tree shaking, consider using: https://material-ui.com/guides/minimizing-bundle-size/
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Router from './Router';
import 'typeface-roboto';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

// @see https://material-ui.com/style/typography/#migration-to-typography-v2
const myWindow = window as any;
myWindow.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

if (process.env.NODE_ENV !== 'production') {
    console.log('APP: ' + process.env.REACT_APP_NAME);
    console.log('ENV: ' + process.env.NODE_ENV);
    console.log('online: ' + navigator.onLine.toString());
}

ReactDOM.render(
    <React.Fragment>
        <Router store={store} />
    </React.Fragment>,
    document.getElementById('root') as HTMLElement
);

registerServiceWorker();
