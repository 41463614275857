import {TOGGLE_DARK_THEME, TOGGLE_LIGHT_THEME} from '../actions/action-types';

const getInitialState = () => {
    return {
        active: 'light'
    };
};

const toggle = (state = getInitialState(), action: any) => {
    switch (action.type) {
        case TOGGLE_DARK_THEME:
            return {
                ...state,
                ...{
                    active: 'dark'
                }
            };
        case TOGGLE_LIGHT_THEME:
            return {
                ...state,
                ...{
                    active: 'light'
                }
            };
        default:
            return state;
    }
};

export const theme = (state: any = {}, action: any) => (toggle(state, action));
