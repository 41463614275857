/**
 * @see https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
 * @see https://reactjs.org/docs/code-splitting.html
 * @see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/19337
 * @see https://github.com/jamiebuilds/react-loadable
 */

import * as React from 'react';
import {Switch, Route, HashRouter} from 'react-router-dom';
import Loadable from 'react-loadable';
import {Provider} from 'react-redux';
import {SHOW_PROGRESS, HIDE_PROGRESS, SHOW_PROGRESS_ERROR, TOGGLE_DARK_THEME} from './actions/action-types';

import MaterialDemo from './demo/MaterialDemo';

interface IProps {
    store: any;
}

export const INITIALIZE_APPLICATION = 'INITIALIZE_APPLICATION';

class Router extends React.Component<IProps, any> {
    public componentDidMount() {
        // @TODO: connect with redux-connect instead of using directly
        this.props.store.dispatch({
            type: INITIALIZE_APPLICATION
        });

        // @TODO: replace theme selection by setting from PouchDB
        this.props.store.dispatch({
            type: TOGGLE_DARK_THEME
        });
}

    /**
     * @TODO: improve progress implementation => maybe move to index.tsx
     */
    private progress = (props: any) => {
        if (props.error) {
            // When the loader has errored
            this.props.store.dispatch({
                type: SHOW_PROGRESS_ERROR,
                error: props.error
            });
        } else if (props.timedOut) {
            // When the loader has taken longer than the timeout
            this.props.store.dispatch({
                type: SHOW_PROGRESS_ERROR,
                error: props.timeOut
            });
        } else if (props.pastDelay) {
            // When the loader has taken longer than the delay
            this.props.store.dispatch({
                type: SHOW_PROGRESS
            });
        } else if (props.isLoading) {
            this.props.store.dispatch({
                type: SHOW_PROGRESS
            });
        } else {
            // When the loader has just started
            this.props.store.dispatch({
                type: HIDE_PROGRESS
            });
        }
        this.props.store.dispatch({
            type: HIDE_PROGRESS
        });
        return null;
    }

    public render() {
        const Start = Loadable({
            loader: () => import('./pages/Start'),
            loading: this.progress
        });

        return (
            <Provider store={this.props.store}>
                <HashRouter hashType="noslash">
                    <Switch>
                        <Route exact={true} path="/" component={Start} />
                        <Route exact={true} path="/demo" component={MaterialDemo} />
                    </Switch>
                </HashRouter>
            </Provider>
        );
    }
}

export default Router;
