import {combineReducers} from 'redux';
import {progress} from './progress';
import {drawer} from './drawer';
import {theme} from './theme';

const reducers = combineReducers({
    progress,
    drawer,
    theme
});

export default reducers;
