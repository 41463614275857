import {createStore, applyMiddleware, compose} from 'redux';
import {createLogger} from 'redux-logger';
import reducer from '../reducers';

const myWindow = window as any;

const getComposer = () => {
    if (myWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return myWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    return compose;
};

const getMiddlewareEnhancer = () => {
    return applyMiddleware(createLogger({collapsed: true}));
};

const configureStore = () => {
    if (process.env.NODE_ENV !== 'production') {
        const composeEnhancers      = getComposer();
        const middlewareEnhancer    = getMiddlewareEnhancer();

        return createStore(
            reducer,
            composeEnhancers(middlewareEnhancer)
        );
    } else {
        return createStore(
            reducer
        );
    }
};

export const store = configureStore();
