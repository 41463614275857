import {SHOW_PROGRESS, HIDE_PROGRESS, SHOW_PROGRESS_ERROR} from '../actions/action-types';

const getInitialState = () => {
    return {
        show: false,
        error: null
    };
};

const showProgress = (state = getInitialState(), action: any) => {
    switch (action.type) {
        case SHOW_PROGRESS:
            return {
                ...state,
                ...{
                    show: true
                }
            };
        case HIDE_PROGRESS:
        return {
            ...state,
            ...{
                show: false
            }
        };
    case SHOW_PROGRESS_ERROR:
        return {
            ...state,
            ...{
                show: false,
                error: state.error
            }
        };
    default:
        return state;
    }
};

export const progress = (state: any = {}, action: any) => (showProgress(state, action));
