import * as React from 'react';
import {withRouter, Link} from 'react-router-dom';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
// import withTheme from '@material-ui/core/styles/withTheme';

import {Helmet} from 'react-helmet';

const styles = (theme: Theme) => createStyles({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing.unit * 20,
    },
});

interface IState {
    open: boolean;
}

// interface IProps {
//     text: string;
// }

class MaterialDemoComponent extends React.Component<any> {
    public state: IState = {
        open: false,
    };

    private handleClose = () => {
        this.setState({
            open: false,
        });

        // // safely modify state based on previous state
        // this.setState((prevState, props) => ({
        //     counter: prevState.counter + props.increment
        // }));
    }

    private handleClick = () => {
        this.setState({
            open: true,
        });
    }

    public render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Demo</title>
                </Helmet>

                <div className={this.props.classes.root}>
                    {/* @see https://reactjs.org/docs/lists-and-keys.html */}
                    {/* @see https://reactjs.org/docs/conditional-rendering.html */}
                    {/* tslint:disable:jsx-no-multiline-js */}
                    {/* Conditions: It works because in JavaScript, true && expression always evaluates to expression, and false && expression always evaluates to false. */}
                    {/*
                        {unreadMessages.length > 0 &&
                            <h2>
                            You have {unreadMessages.length} unread messages.
                            </h2>
                        }
                        or:
                        {isLoggedIn ? (
                            <LogoutButton onClick={this.handleLogoutClick} />
                        ) : (
                            <LoginButton onClick={this.handleLoginClick} />
                        )}
                    */}
                    <Dialog open={this.state.open} onClose={this.handleClose}>
                        <DialogTitle>Super Secret Password</DialogTitle>

                        <DialogContent>
                            <DialogContentText>1-2-3-4-5</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {/* event handler call with second parameter */}
                            {/* <Button onClick={this.deleteRow.bind(this, id)}>Delete Row</button> */}
                            <Button color="primary" onClick={this.handleClose}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Button variant="raised" color="secondary" onClick={this.handleClick}>
                        Super Secret Password
                    </Button>

                    <Link to="/">Home</Link>
                </div>
            </React.Fragment>
        );
    }
}

export const MaterialDemo = withStyles(styles)(withRouter(MaterialDemoComponent));
// export const MaterialDemo = withTheme()(withRouter(MaterialDemoComponent));

export default MaterialDemo;
